@charset "UTF-8";
.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}

:root {
  --ba-bg-primary: #52759f;
  --ba-primary-bg-color: #fff;
  --ba-light-blue: #8abce6;
  --ba-card-header-bg: #52759f;
  --ba-card-header-color: #fff;
  --primary: #2e4159;
  --secondary: #e92422;
  --info: #52759f;
  --ba-record-toolbar-card-border-color: #5d6971;
  --ba-input-min-height-lg: 60px;
  --ba-input-padding-y-lg: 0.75rem;
  --ba-input-padding-x-lg: 0.75rem;
  --ba-multiselect-tags-padding-top: 6px;
  --ba-profile-section-padding-x: 15px;
  --ba-profile-section-padding-y: 20px;
}

@media (min-width: 1260px) {
  :root {
    --ba-profile-section-padding-x: 50px;
    --ba-profile-section-padding-y: 36px;
  }
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e92422;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #31e02d;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #626262;
  --gray-dark: #232323;
  --primary: #2e4159;
  --secondary: #e92422;
  --success: #31e02d;
  --info: #52759f;
  --warning: #ffc107;
  --danger: #e92422;
  --light: #f4f4f4;
  --dark: #232323;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1260px;
  --font-family-sans-serif: "GenerisSans", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "GenerisSans", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1a1a;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3c536c;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #2e4159;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #626262;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.3125rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #232323;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.25rem;
}

h3, .h3 {
  font-size: 1.1875rem;
}

h4, .h4 {
  font-size: 1.1875rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.1875rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #626262;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #626262;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1260px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1260px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0.6875rem 0.875rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125;
  color: #626262;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #626262;
  background-color: #fff;
  border-color: #5c7fab;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.form-control::placeholder {
  color: #b0b0b0;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #626262;
}
select.form-control:focus::-ms-value {
  color: #626262;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.3125;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6875rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.3125;
  color: #626262;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 2rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #626262;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #31e02d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #1a1a1a;
  background-color: #31e02d;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #31e02d;
  padding-right: calc(1.3125em + 1.375rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2331e02d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.328125em + 0.34375rem) center;
  background-size: calc(0.65625em + 0.6875rem) calc(0.65625em + 0.6875rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #31e02d;
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3.5rem !important;
  background-position: right 1.75rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.3125em + 1.375rem);
  background-position: top calc(0.328125em + 0.34375rem) right calc(0.328125em + 0.34375rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #31e02d;
  padding-right: calc(0.75em + 2.90625rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23232323' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.875rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2331e02d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.875rem/calc(0.65625em + 0.6875rem) calc(0.65625em + 0.6875rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #31e02d;
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #31e02d;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #31e02d;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #31e02d;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5de759;
  background-color: #5de759;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #31e02d;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #31e02d;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #31e02d;
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e92422;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #e92422;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e92422;
  padding-right: calc(1.3125em + 1.375rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e92422' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e92422' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.328125em + 0.34375rem) center;
  background-size: calc(0.65625em + 0.6875rem) calc(0.65625em + 0.6875rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e92422;
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3.5rem !important;
  background-position: right 1.75rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.3125em + 1.375rem);
  background-position: top calc(0.328125em + 0.34375rem) right calc(0.328125em + 0.34375rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e92422;
  padding-right: calc(0.75em + 2.90625rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23232323' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.875rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e92422' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e92422' stroke='none'/%3e%3c/svg%3e") center right 1.875rem/calc(0.65625em + 0.6875rem) calc(0.65625em + 0.6875rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e92422;
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e92422;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e92422;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e92422;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ee5250;
  background-color: #ee5250;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e92422;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e92422;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e92422;
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #1a1a1a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0.8125rem 0.875rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #1a1a1a;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #2e4159;
  border-color: #2e4159;
}
.btn-primary:hover {
  color: #fff;
  background-color: #212f40;
  border-color: #1d2837;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #212f40;
  border-color: #1d2837;
  box-shadow: 0 0 0 0 rgba(77, 94, 114, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2e4159;
  border-color: #2e4159;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1d2837;
  border-color: #18222f;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(77, 94, 114, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #d01715;
  border-color: #c41514;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #d01715;
  border-color: #c41514;
  box-shadow: 0 0 0 0 rgba(236, 69, 67, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #c41514;
  border-color: #b91412;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(236, 69, 67, 0.5);
}

.btn-success {
  color: #1a1a1a;
  background-color: #31e02d;
  border-color: #31e02d;
}
.btn-success:hover {
  color: #fff;
  background-color: #22c91e;
  border-color: #20be1c;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #22c91e;
  border-color: #20be1c;
  box-shadow: 0 0 0 0 rgba(46, 194, 42, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #1a1a1a;
  background-color: #31e02d;
  border-color: #31e02d;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #20be1c;
  border-color: #1eb31a;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(46, 194, 42, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #52759f;
  border-color: #52759f;
}
.btn-info:hover {
  color: #fff;
  background-color: #456286;
  border-color: #415c7d;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #456286;
  border-color: #415c7d;
  box-shadow: 0 0 0 0 rgba(108, 138, 173, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #52759f;
  border-color: #52759f;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #415c7d;
  border-color: #3c5675;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(108, 138, 173, 0.5);
}

.btn-warning {
  color: #1a1a1a;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #1a1a1a;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #1a1a1a;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0 rgba(221, 168, 10, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #1a1a1a;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #1a1a1a;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 168, 10, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d01715;
  border-color: #c41514;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d01715;
  border-color: #c41514;
  box-shadow: 0 0 0 0 rgba(236, 69, 67, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c41514;
  border-color: #b91412;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(236, 69, 67, 0.5);
}

.btn-light {
  color: #1a1a1a;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-light:hover {
  color: #1a1a1a;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
.btn-light:focus, .btn-light.focus {
  color: #1a1a1a;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #1a1a1a;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #1a1a1a;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-dark:hover {
  color: #fff;
  background-color: #101010;
  border-color: #0a0a0a;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #101010;
  border-color: #0a0a0a;
  box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #030303;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.5);
}

.btn-outline-primary {
  color: #2e4159;
  border-color: #2e4159;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #2e4159;
  border-color: #2e4159;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2e4159;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2e4159;
  border-color: #2e4159;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.5);
}

.btn-outline-secondary {
  color: #e92422;
  border-color: #e92422;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #e92422;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.5);
}

.btn-outline-success {
  color: #31e02d;
  border-color: #31e02d;
}
.btn-outline-success:hover {
  color: #1a1a1a;
  background-color: #31e02d;
  border-color: #31e02d;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #31e02d;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #1a1a1a;
  background-color: #31e02d;
  border-color: #31e02d;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.5);
}

.btn-outline-info {
  color: #52759f;
  border-color: #52759f;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #52759f;
  border-color: #52759f;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(82, 117, 159, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #52759f;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #52759f;
  border-color: #52759f;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 117, 159, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #1a1a1a;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #1a1a1a;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #e92422;
  border-color: #e92422;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e92422;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e92422;
  border-color: #e92422;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.5);
}

.btn-outline-light {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light:hover {
  color: #1a1a1a;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(244, 244, 244, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #1a1a1a;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(244, 244, 244, 0.5);
}

.btn-outline-dark {
  color: #232323;
  border-color: #232323;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(35, 35, 35, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #232323;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(35, 35, 35, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3c536c;
  text-decoration: none;
}
.btn-link:hover {
  color: #2e4159;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #626262;
  pointer-events: none;
}

.btn-lg {
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1a1a1a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1260px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1a1a1a;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #0d0d0d;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2e4159;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #858585;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #626262;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #1a1a1a;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6875rem 0.875rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125;
  color: #626262;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #d8d8d8;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.5rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.75rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.875rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #858585;
  border-color: #2e4159;
  background-color: #2e4159;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5c7fab;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #7d99bc;
  border-color: #7d99bc;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #626262;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23858585' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2e4159;
  background-color: #2e4159;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23858585' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(46, 65, 89, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(46, 65, 89, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23858585'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(46, 65, 89, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #d8d8d8;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(46, 65, 89, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0.6875rem 1.875rem 0.6875rem 0.875rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125;
  color: #626262;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23232323' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.875rem center/8px 10px no-repeat;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: #5c7fab;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.custom-select:focus::-ms-value {
  color: #626262;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.875rem;
  background-image: none;
}
.custom-select:disabled {
  color: #626262;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #626262;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.5rem + 2px);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.3125em + 1.375rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.3125em + 1.375rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #5c7fab;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0.6875rem 0.875rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.3125;
  color: #626262;
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.3125em + 1.375rem);
  padding: 0.6875rem 0.875rem;
  line-height: 1.3125;
  color: #626262;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2e4159;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #7d99bc;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d8d8d8;
  border-color: transparent;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2e4159;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #7d99bc;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d8d8d8;
  border-color: transparent;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #2e4159;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #7d99bc;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #d8d8d8;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d8d8d8;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #858585;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #858585;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #858585;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.625rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #626262;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #d8d8d8;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #d8d8d8;
}
.nav-tabs .nav-link.disabled {
  color: #626262;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #d8d8d8 #d8d8d8 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2e4159;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1259.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1260px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #3c536c;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #3c536c;
}
.navbar-light .navbar-nav .nav-link {
  color: #3c536c;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #3c536c;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #3c536c;
}
.navbar-light .navbar-toggler {
  color: #3c536c;
  border-color: #3c536c;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%233c536c' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #3c536c;
}
.navbar-light .navbar-text a {
  color: #3c536c;
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #3c536c;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
}
.accordion > .card > .card-header {
  margin-bottom: -1px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3c536c;
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #2e4159;
  border-color: #2e4159;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2e4159;
  border-color: #2e4159;
}
.page-item.disabled .page-link {
  color: #626262;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #d8d8d8;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #18222e;
  background-color: #d5d9de;
  border-color: #c4cad1;
}
.alert-primary hr {
  border-top-color: #b6bdc6;
}
.alert-primary .alert-link {
  color: #07090c;
}

.alert-secondary {
  color: #791312;
  background-color: #fbd3d3;
  border-color: #f9c2c1;
}
.alert-secondary hr {
  border-top-color: #f7abaa;
}
.alert-secondary .alert-link {
  color: #4d0c0b;
}

.alert-success {
  color: #197417;
  background-color: #d6f9d5;
  border-color: #c5f6c4;
}
.alert-success hr {
  border-top-color: #aff3ae;
}
.alert-success .alert-link {
  color: #10490f;
}

.alert-info {
  color: #2b3d53;
  background-color: #dce3ec;
  border-color: #cfd8e4;
}
.alert-info hr {
  border-top-color: #bfcbdb;
}
.alert-info .alert-link {
  color: #1a2431;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #791312;
  background-color: #fbd3d3;
  border-color: #f9c2c1;
}
.alert-danger hr {
  border-top-color: #f7abaa;
}
.alert-danger .alert-link {
  color: #4d0c0b;
}

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light hr {
  border-top-color: #efefef;
}
.alert-light .alert-link {
  color: #666666;
}

.alert-dark {
  color: #121212;
  background-color: lightgray;
  border-color: #c1c1c1;
}
.alert-dark hr {
  border-top-color: #b4b4b4;
}
.alert-dark .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1.1875rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1.1875rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #d8d8d8;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #52759f;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.1875rem 1.1875rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 50px;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 100px);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 100px);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 100px);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 100px);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #2e4159;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.65;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 0 solid #d8d8d8;
}
.modal-header .close {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0 solid #d8d8d8;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 100px auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 200px);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 200px);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 200px);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 200px);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1260px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "GenerisSans", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top], .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}

.bs-tooltip-right, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right], .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-bottom, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}

.bs-tooltip-left, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left], .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #1a1a1a;
  text-align: center;
  background-color: #fff;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2e4159 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1d2837 !important;
}

.bg-secondary {
  background-color: #e92422 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c41514 !important;
}

.bg-success {
  background-color: #31e02d !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #20be1c !important;
}

.bg-info {
  background-color: #52759f !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #415c7d !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #e92422 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c41514 !important;
}

.bg-light {
  background-color: #f4f4f4 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dbdbdb !important;
}

.bg-dark {
  background-color: #232323 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0a0a0a !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #d8d8d8 !important;
}

.border-top {
  border-top: 1px solid #d8d8d8 !important;
}

.border-right {
  border-right: 1px solid #d8d8d8 !important;
}

.border-bottom {
  border-bottom: 1px solid #d8d8d8 !important;
}

.border-left {
  border-left: 1px solid #d8d8d8 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2e4159 !important;
}

.border-secondary {
  border-color: #e92422 !important;
}

.border-success {
  border-color: #31e02d !important;
}

.border-info {
  border-color: #52759f !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #e92422 !important;
}

.border-light {
  border-color: #f4f4f4 !important;
}

.border-dark {
  border-color: #232323 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1260px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1260px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1260px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1260px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1260px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #2e4159 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #141c27 !important;
}

.text-secondary {
  color: #e92422 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #ad1311 !important;
}

.text-success {
  color: #31e02d !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1ca819 !important;
}

.text-info {
  color: #52759f !important;
}

a.text-info:hover, a.text-info:focus {
  color: #38506d !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #e92422 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ad1311 !important;
}

.text-light {
  color: #f4f4f4 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cecece !important;
}

.text-dark {
  color: #232323 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #1a1a1a !important;
}

.text-muted {
  color: #626262 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #858585;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #d8d8d8 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #d8d8d8;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #d8d8d8;
  }
}
.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}
@media (max-width: 1259.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}
.bv-d-xl-down-none {
  display: none !important;
}

.form-control.focus {
  color: #626262;
  background-color: #fff;
  border-color: #5c7fab;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.form-control.focus.is-valid {
  border-color: #31e02d;
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}
.form-control.focus.is-invalid {
  border-color: #e92422;
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.b-avatar:focus {
  outline: 0;
}
.b-avatar.btn, .b-avatar[href] {
  padding: 0;
  border: 0;
}
.b-avatar.btn .b-avatar-img img, .b-avatar[href] .b-avatar-img img {
  transition: transform 0.15s ease-in-out;
}
.b-avatar.btn:not(:disabled):not(.disabled), .b-avatar[href]:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img, .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
  transform: scale(1.15);
}
.b-avatar.disabled, .b-avatar:disabled, .b-avatar[disabled] {
  opacity: 0.65;
  pointer-events: none;
}
.b-avatar .b-avatar-custom,
.b-avatar .b-avatar-text,
.b-avatar .b-avatar-img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: radial-gradient(white, black);
}
.b-avatar .b-avatar-text {
  text-transform: uppercase;
  white-space: nowrap;
}
.b-avatar[href] {
  text-decoration: none;
}
.b-avatar > .b-icon {
  width: 60%;
  height: auto;
  max-width: 100%;
}
.b-avatar .b-avatar-img img {
  width: 100%;
  height: 100%;
  max-height: auto;
  border-radius: inherit;
  object-fit: cover;
}
.b-avatar .b-avatar-badge {
  position: absolute;
  min-height: 1.5em;
  min-width: 1.5em;
  padding: 0.25em;
  line-height: 1;
  border-radius: 10em;
  font-size: 70%;
  font-weight: 700;
  z-index: 1;
}

.b-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}
.b-avatar-sm .b-avatar-text {
  font-size: calc(0.6rem);
}
.b-avatar-sm .b-avatar-badge {
  font-size: calc(0.42rem);
}

.b-avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}
.b-avatar-lg .b-avatar-text {
  font-size: calc(1.4rem);
}
.b-avatar-lg .b-avatar-badge {
  font-size: calc(0.98rem);
}

.b-avatar-group .b-avatar-group-inner {
  display: flex;
  flex-wrap: wrap;
}
.b-avatar-group .b-avatar {
  border: 1px solid #d8d8d8;
}
.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled),
.b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled) {
  z-index: 1;
}

.b-calendar {
  display: inline-flex;
}
.b-calendar .b-calendar-inner {
  min-width: 250px;
}
.b-calendar .b-calendar-header,
.b-calendar .b-calendar-nav {
  margin-bottom: 0.25rem;
}
.b-calendar .b-calendar-nav .btn {
  padding: 0.25rem;
}
.b-calendar output {
  padding: 0.25rem;
  font-size: 80%;
}
.b-calendar output.readonly {
  background-color: #e9ecef;
  opacity: 1;
}
.b-calendar .b-calendar-footer {
  margin-top: 0.5rem;
}
.b-calendar .b-calendar-grid {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.b-calendar .b-calendar-grid .row {
  flex-wrap: nowrap;
}
.b-calendar .b-calendar-grid-caption {
  padding: 0.25rem;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 1;
  margin: 3px auto;
  padding: 9px 0;
}
.b-calendar .btn:disabled, .b-calendar .btn.disabled, .b-calendar .btn[aria-disabled=true] {
  cursor: default;
  pointer-events: none;
}

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important;
}
.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important;
}
.dropdown .dropdown-menu:focus {
  outline: none;
}

.b-dropdown-form {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  width: 100%;
  clear: both;
  font-weight: 400;
}
.b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.b-dropdown-form.disabled, .b-dropdown-form:disabled {
  outline: 0 !important;
  color: #858585;
  pointer-events: none;
}

.b-dropdown-text {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 1.875rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-checkbox .custom-control-label::before {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background-size: 100% 100%;
}

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 1.3125rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-checkbox .custom-control-label::before {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-checkbox .custom-control-label::after {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background-size: 100% 100%;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 2.8125rem;
}
.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 1.25rem;
  line-height: 1.5;
}
.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  top: 0.3125rem;
  height: 1.25rem;
  left: -2.8125rem;
  width: 2.1875rem;
  border-radius: 0.625rem;
}
.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc(0.3125rem + 2px);
  left: calc(-2.8125rem + 2px);
  width: calc(1.25rem - 4px);
  height: calc(1.25rem - 4px);
  border-radius: 0.625rem;
  background-size: 100% 100%;
}
.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.9375rem);
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 1.96875rem;
}
.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
  font-size: 0.875rem;
  line-height: 1.5;
}
.custom-switch.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-switch .custom-control-label::before {
  top: 0.21875rem;
  left: -1.96875rem;
  width: 1.53125rem;
  height: 0.875rem;
  border-radius: 0.4375rem;
}
.custom-switch.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-label::after {
  top: calc(0.21875rem + 2px);
  left: calc(-1.96875rem + 2px);
  width: calc(0.875rem - 4px);
  height: calc(0.875rem - 4px);
  border-radius: 0.4375rem;
  background-size: 100% 100%;
}
.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.65625rem);
}

.input-group > .input-group-prepend > .btn-group > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.b-form-btn-label-control.form-control {
  display: flex;
  align-items: stretch;
  height: auto;
  padding: 0;
  background-image: none;
}
.input-group .b-form-btn-label-control.form-control {
  padding: 0;
}

[dir=rtl] .b-form-btn-label-control.form-control, .b-form-btn-label-control.form-control[dir=rtl] {
  flex-direction: row-reverse;
}
[dir=rtl] .b-form-btn-label-control.form-control > label, .b-form-btn-label-control.form-control[dir=rtl] > label {
  text-align: right;
}

.b-form-btn-label-control.form-control > .btn {
  line-height: 1;
  font-size: inherit;
  box-shadow: none !important;
  border: 0;
}
.b-form-btn-label-control.form-control > .btn:disabled {
  pointer-events: none;
}
.b-form-btn-label-control.form-control.is-valid > .btn {
  color: #31e02d;
}
.b-form-btn-label-control.form-control.is-invalid > .btn {
  color: #e92422;
}
.b-form-btn-label-control.form-control > .dropdown-menu {
  padding: 0.5rem;
}
.b-form-btn-label-control.form-control > .form-control {
  height: auto;
  min-height: calc(calc(1.3125em + 1.375rem + 2px) - 2px);
  padding-left: 0.25rem;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
}
.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  min-height: calc(calc(1.5em + 0.5rem + 2px) - 2px);
}
.b-form-btn-label-control.form-control > .form-control.form-control-lg {
  min-height: calc(calc(1.5em + 1.5rem + 2px) - 2px);
}
.input-group.input-group-sm .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.5em + 0.5rem + 2px) - 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.input-group.input-group-lg .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.5em + 1.5rem + 2px) - 2px);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.b-form-btn-label-control.form-control[aria-disabled=true], .b-form-btn-label-control.form-control[aria-readonly=true] {
  background-color: #e9ecef;
  opacity: 1;
}
.b-form-btn-label-control.form-control[aria-disabled=true] {
  pointer-events: none;
}
.b-form-btn-label-control.form-control[aria-disabled=true] > label {
  cursor: default;
}

.b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: 0.5rem;
}

.custom-file-label {
  white-space: nowrap;
  overflow-x: hidden;
}

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 1.25rem;
  height: calc(1.5em + 1.5rem + 2px);
}
.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.75rem 0.75rem;
  line-height: 1.5;
}
.b-custom-control-lg .custom-file-label::after,
.input-group-lg .custom-file-label::after {
  font-size: inherit;
  height: calc(1.5em + 1.5rem);
}

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
}
.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}
.b-custom-control-sm .custom-file-label::after,
.input-group-sm .custom-file-label::after {
  font-size: inherit;
  height: calc(1.5em + 0.5rem);
}

.was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(0.328125em + 0.34375rem) center;
}

input[type=color].form-control {
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control.form-control-sm,
.input-group-sm input[type=color].form-control {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control.form-control-lg,
.input-group-lg input[type=color].form-control {
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control:disabled {
  background-color: #858585;
  opacity: 0.65;
}

.input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-range + .form-control,
.input-group > .custom-range + .form-control-plaintext,
.input-group > .custom-range + .custom-select,
.input-group > .custom-range + .custom-range,
.input-group > .custom-range + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range,
.input-group > .custom-select + .custom-range,
.input-group > .custom-range + .custom-range,
.input-group > .custom-file + .custom-range {
  margin-left: -1px;
}
.input-group > .custom-range:focus {
  z-index: 3;
}
.input-group > .custom-range {
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0 0.875rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d8d8;
  height: calc(1.3125em + 1.375rem + 2px);
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group > .custom-range {
    transition: none;
  }
}
.input-group > .custom-range:focus {
  color: #626262;
  background-color: #fff;
  border-color: #5c7fab;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.input-group > .custom-range:disabled, .input-group > .custom-range[readonly] {
  background-color: #e9ecef;
}

.input-group-lg > .custom-range {
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0 0.75rem;
}

.input-group-sm > .custom-range {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0 0.5rem;
}

.was-validated .input-group .custom-range:valid, .input-group .custom-range.is-valid {
  border-color: #31e02d;
}
.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
  border-color: #31e02d;
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 #caf7c9;
}
.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 #caf7c9;
}
.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 #caf7c9;
}
.was-validated .custom-range:valid::-webkit-slider-thumb, .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #31e02d;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-thumb:active, .custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #caf7c9;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-runnable-track, .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(49, 224, 45, 0.35);
}
.was-validated .custom-range:valid::-moz-range-thumb, .custom-range.is-valid::-moz-range-thumb {
  background-color: #31e02d;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-thumb:active, .custom-range.is-valid::-moz-range-thumb:active {
  background-color: #caf7c9;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-track, .custom-range.is-valid::-moz-range-track {
  background: rgba(49, 224, 45, 0.35);
}
.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip, .custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-range:valid::-ms-thumb, .custom-range.is-valid::-ms-thumb {
  background-color: #31e02d;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-thumb:active, .custom-range.is-valid::-ms-thumb:active {
  background-color: #caf7c9;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-track-lower, .custom-range.is-valid::-ms-track-lower {
  background: rgba(49, 224, 45, 0.35);
}
.was-validated .custom-range:valid::-ms-track-upper, .custom-range.is-valid::-ms-track-upper {
  background: rgba(49, 224, 45, 0.35);
}

.was-validated .input-group .custom-range:invalid, .input-group .custom-range.is-invalid {
  border-color: #e92422;
}
.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
  border-color: #e92422;
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 #f9c5c4;
}
.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 #f9c5c4;
}
.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 #f9c5c4;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb, .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #e92422;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb:active, .custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #f9c5c4;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-runnable-track, .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(233, 36, 34, 0.35);
}
.was-validated .custom-range:invalid::-moz-range-thumb, .custom-range.is-invalid::-moz-range-thumb {
  background-color: #e92422;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-thumb:active, .custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #f9c5c4;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-track, .custom-range.is-invalid::-moz-range-track {
  background: rgba(233, 36, 34, 0.35);
}
.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip, .custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-range:invalid::-ms-thumb, .custom-range.is-invalid::-ms-thumb {
  background-color: #e92422;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-thumb:active, .custom-range.is-invalid::-ms-thumb:active {
  background-color: #f9c5c4;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-track-lower, .custom-range.is-invalid::-ms-track-lower {
  background: rgba(233, 36, 34, 0.35);
}
.was-validated .custom-range:invalid::-ms-track-upper, .custom-range.is-invalid::-ms-track-upper {
  background: rgba(233, 36, 34, 0.35);
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 1.875rem;
}
.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}
.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background: no-repeat 50%/100% 100%;
}

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 1.3125rem;
}
.custom-radio.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-radio .custom-control-label::before {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
}
.custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background: no-repeat 50%/100% 100%;
}

.b-rating {
  text-align: center;
}
.b-rating.d-inline-flex {
  width: auto;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.25em;
}
.b-rating .b-rating-value {
  min-width: 2.5em;
}
.b-rating .b-rating-star {
  display: inline-flex;
  justify-content: center;
  outline: 0;
}
.b-rating .b-rating-star .b-rating-icon {
  display: inline-flex;
  transition: all 0.15s ease-in-out;
}
.b-rating.disabled, .b-rating:disabled {
  background-color: #e9ecef;
  color: #626262;
}
.b-rating:not(.disabled):not(.readonly) .b-rating-star {
  cursor: pointer;
}
.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,
.b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
  transform: scale(1.5);
}
.b-rating[dir=rtl] .b-rating-star-half {
  transform: scale(-1, 1);
}

.b-form-spinbutton {
  text-align: center;
  overflow: hidden;
  background-image: none;
  padding: 0;
}
[dir=rtl] .b-form-spinbutton:not(.flex-column), .b-form-spinbutton[dir=rtl]:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-form-spinbutton output {
  font-size: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0 0.25rem;
}
.b-form-spinbutton output > div,
.b-form-spinbutton output > bdi {
  display: block;
  min-width: 2.25em;
  height: 1.3125em;
}
.b-form-spinbutton.flex-column {
  height: auto;
  width: auto;
}
.b-form-spinbutton.flex-column output {
  margin: 0 0.25rem;
  padding: 0.25rem 0;
}
.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  output-width: 100%;
}
.b-form-spinbutton.d-inline-flex:not(.flex-column) {
  width: auto;
}
.b-form-spinbutton .btn {
  line-height: 1;
  box-shadow: none !important;
}
.b-form-spinbutton .btn:disabled {
  pointer-events: none;
}
.b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
  transform: scale(1.25);
}
.b-form-spinbutton.disabled, .b-form-spinbutton.readonly {
  background-color: #e9ecef;
}
.b-form-spinbutton.disabled {
  pointer-events: none;
}

.b-form-tags.focus {
  color: #626262;
  background-color: #fff;
  border-color: #5c7fab;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(46, 65, 89, 0.25);
}
.b-form-tags.focus.is-valid {
  border-color: #31e02d;
  box-shadow: 0 0 0 0 rgba(49, 224, 45, 0.25);
}
.b-form-tags.focus.is-invalid {
  border-color: #e92422;
  box-shadow: 0 0 0 0 rgba(233, 36, 34, 0.25);
}
.b-form-tags.disabled {
  background-color: #e9ecef;
}

.b-form-tags-list {
  margin-top: -0.25rem;
}
.b-form-tags-list .b-form-tags-field,
.b-form-tags-list .b-form-tag {
  margin-top: 0.25rem;
}

.b-form-tags-input {
  color: #626262;
}

.b-form-tag {
  font-size: 75%;
  font-weight: normal;
  line-height: 1.3125;
  margin-right: 0.25rem;
}
.b-form-tag.disabled {
  opacity: 0.75;
}
.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: 0.25rem;
}

.form-control-sm .b-form-tag {
  line-height: 1.5;
}

.form-control-lg .b-form-tag {
  line-height: 1.5;
}

.media-aside {
  display: flex;
  margin-right: 1rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 1rem;
}

.modal-backdrop {
  opacity: 0.65;
}

.b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}
.b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}

.popover.b-popover {
  display: block;
  opacity: 1;
  outline: 0;
}
.popover.b-popover.fade:not(.show) {
  opacity: 0;
}
.popover.b-popover.show {
  opacity: 1;
}

.b-popover-primary.popover {
  background-color: #d5d9de;
  border-color: #c4cad1;
}
.b-popover-primary.bs-popover-top > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c4cad1;
}
.b-popover-primary.bs-popover-top > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d5d9de;
}
.b-popover-primary.bs-popover-right > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c4cad1;
}
.b-popover-primary.bs-popover-right > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d5d9de;
}
.b-popover-primary.bs-popover-bottom > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c4cad1;
}
.b-popover-primary.bs-popover-bottom > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #ccd1d7;
}
.b-popover-primary.bs-popover-bottom .popover-header::before, .b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #ccd1d7;
}
.b-popover-primary.bs-popover-left > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c4cad1;
}
.b-popover-primary.bs-popover-left > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d5d9de;
}
.b-popover-primary .popover-header {
  color: #1a1a1a;
  background-color: #ccd1d7;
  border-bottom-color: #bec4cc;
}
.b-popover-primary .popover-body {
  color: #18222e;
}

.b-popover-secondary.popover {
  background-color: #fbd3d3;
  border-color: #f9c2c1;
}
.b-popover-secondary.bs-popover-top > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #f9c2c1;
}
.b-popover-secondary.bs-popover-top > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fbd3d3;
}
.b-popover-secondary.bs-popover-right > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #f9c2c1;
}
.b-popover-secondary.bs-popover-right > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fbd3d3;
}
.b-popover-secondary.bs-popover-bottom > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #f9c2c1;
}
.b-popover-secondary.bs-popover-bottom > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #fac5c5;
}
.b-popover-secondary.bs-popover-bottom .popover-header::before, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #fac5c5;
}
.b-popover-secondary.bs-popover-left > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #f9c2c1;
}
.b-popover-secondary.bs-popover-left > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fbd3d3;
}
.b-popover-secondary .popover-header {
  color: #1a1a1a;
  background-color: #fac5c5;
  border-bottom-color: #f8aeae;
}
.b-popover-secondary .popover-body {
  color: #791312;
}

.b-popover-success.popover {
  background-color: #d6f9d5;
  border-color: #c5f6c4;
}
.b-popover-success.bs-popover-top > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c5f6c4;
}
.b-popover-success.bs-popover-top > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d6f9d5;
}
.b-popover-success.bs-popover-right > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c5f6c4;
}
.b-popover-success.bs-popover-right > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d6f9d5;
}
.b-popover-success.bs-popover-bottom > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c5f6c4;
}
.b-popover-success.bs-popover-bottom > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #c9f7c8;
}
.b-popover-success.bs-popover-bottom .popover-header::before, .b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #c9f7c8;
}
.b-popover-success.bs-popover-left > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c5f6c4;
}
.b-popover-success.bs-popover-left > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d6f9d5;
}
.b-popover-success .popover-header {
  color: #1a1a1a;
  background-color: #c9f7c8;
  border-bottom-color: #b3f4b1;
}
.b-popover-success .popover-body {
  color: #197417;
}

.b-popover-info.popover {
  background-color: #dce3ec;
  border-color: #cfd8e4;
}
.b-popover-info.bs-popover-top > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #cfd8e4;
}
.b-popover-info.bs-popover-top > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #dce3ec;
}
.b-popover-info.bs-popover-right > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #cfd8e4;
}
.b-popover-info.bs-popover-right > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #dce3ec;
}
.b-popover-info.bs-popover-bottom > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #cfd8e4;
}
.b-popover-info.bs-popover-bottom > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #d2dbe7;
}
.b-popover-info.bs-popover-bottom .popover-header::before, .b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #d2dbe7;
}
.b-popover-info.bs-popover-left > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #cfd8e4;
}
.b-popover-info.bs-popover-left > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #dce3ec;
}
.b-popover-info .popover-header {
  color: #1a1a1a;
  background-color: #d2dbe7;
  border-bottom-color: #c2cede;
}
.b-popover-info .popover-body {
  color: #2b3d53;
}

.b-popover-warning.popover {
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.b-popover-warning.bs-popover-top > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #ffeeba;
}
.b-popover-warning.bs-popover-top > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fff3cd;
}
.b-popover-warning.bs-popover-right > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #ffeeba;
}
.b-popover-warning.bs-popover-right > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fff3cd;
}
.b-popover-warning.bs-popover-bottom > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #ffeeba;
}
.b-popover-warning.bs-popover-bottom > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #ffefbe;
}
.b-popover-warning.bs-popover-bottom .popover-header::before, .b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #ffefbe;
}
.b-popover-warning.bs-popover-left > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #ffeeba;
}
.b-popover-warning.bs-popover-left > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fff3cd;
}
.b-popover-warning .popover-header {
  color: #1a1a1a;
  background-color: #ffefbe;
  border-bottom-color: #ffe9a4;
}
.b-popover-warning .popover-body {
  color: #856404;
}

.b-popover-danger.popover {
  background-color: #fbd3d3;
  border-color: #f9c2c1;
}
.b-popover-danger.bs-popover-top > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #f9c2c1;
}
.b-popover-danger.bs-popover-top > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fbd3d3;
}
.b-popover-danger.bs-popover-right > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #f9c2c1;
}
.b-popover-danger.bs-popover-right > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fbd3d3;
}
.b-popover-danger.bs-popover-bottom > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #f9c2c1;
}
.b-popover-danger.bs-popover-bottom > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #fac5c5;
}
.b-popover-danger.bs-popover-bottom .popover-header::before, .b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #fac5c5;
}
.b-popover-danger.bs-popover-left > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #f9c2c1;
}
.b-popover-danger.bs-popover-left > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fbd3d3;
}
.b-popover-danger .popover-header {
  color: #1a1a1a;
  background-color: #fac5c5;
  border-bottom-color: #f8aeae;
}
.b-popover-danger .popover-body {
  color: #791312;
}

.b-popover-light.popover {
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.b-popover-light.bs-popover-top > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #fcfcfc;
}
.b-popover-light.bs-popover-top > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fdfdfd;
}
.b-popover-light.bs-popover-right > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #fcfcfc;
}
.b-popover-light.bs-popover-right > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fdfdfd;
}
.b-popover-light.bs-popover-bottom > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #fcfcfc;
}
.b-popover-light.bs-popover-bottom > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: whitesmoke;
}
.b-popover-light.bs-popover-bottom .popover-header::before, .b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: whitesmoke;
}
.b-popover-light.bs-popover-left > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #fcfcfc;
}
.b-popover-light.bs-popover-left > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fdfdfd;
}
.b-popover-light .popover-header {
  color: #1a1a1a;
  background-color: whitesmoke;
  border-bottom-color: #e9e9e9;
}
.b-popover-light .popover-body {
  color: #7f7f7f;
}

.b-popover-dark.popover {
  background-color: lightgray;
  border-color: #c1c1c1;
}
.b-popover-dark.bs-popover-top > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c1c1c1;
}
.b-popover-dark.bs-popover-top > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: lightgray;
}
.b-popover-dark.bs-popover-right > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c1c1c1;
}
.b-popover-dark.bs-popover-right > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: lightgray;
}
.b-popover-dark.bs-popover-bottom > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c1c1c1;
}
.b-popover-dark.bs-popover-bottom > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #cbcbcb;
}
.b-popover-dark.bs-popover-bottom .popover-header::before, .b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #cbcbcb;
}
.b-popover-dark.bs-popover-left > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c1c1c1;
}
.b-popover-dark.bs-popover-left > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: lightgray;
}
.b-popover-dark .popover-header {
  color: #1a1a1a;
  background-color: #cbcbcb;
  border-bottom-color: #bfbfbf;
}
.b-popover-dark .popover-body {
  color: #121212;
}

.b-sidebar-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: calc(1030 + 5);
}

.b-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}

.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}
.b-sidebar.slide {
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .b-sidebar.slide {
    transition: none;
  }
}
.b-sidebar:not(.b-sidebar-right) {
  left: 0;
  right: auto;
}
.b-sidebar:not(.b-sidebar-right).slide:not(.show) {
  transform: translateX(-100%);
}
.b-sidebar:not(.b-sidebar-right) > .b-sidebar-header .close {
  margin-left: auto;
}
.b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
}
.b-sidebar.b-sidebar-right.slide:not(.show) {
  transform: translateX(100%);
}
.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
}
.b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}
[dir=rtl] .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
}

.b-sidebar > .b-sidebar-header .close {
  float: none;
  font-size: 1.5rem;
}
.b-sidebar > .b-sidebar-body {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}
.b-sidebar > .b-sidebar-footer {
  flex-grow: 0;
}

.b-skeleton-wrapper {
  cursor: wait;
}

.b-skeleton {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: wait;
  mask-image: radial-gradient(white, black);
}
.b-skeleton::before {
  content: " ";
}

.b-skeleton-text {
  height: 1rem;
  margin-bottom: 0.25rem;
}

.b-skeleton-button {
  width: 75px;
  padding: 0.8125rem 0.875rem;
  font-size: 1rem;
  line-height: 1.25;
}

.b-skeleton-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.b-skeleton-input {
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0.6875rem 0.875rem;
  line-height: 1.3125;
  border: #d8d8d8 solid 1px;
}

.b-skeleton-icon-wrapper svg {
  color: rgba(0, 0, 0, 0.12);
}

.b-skeleton-img {
  height: 100%;
  width: 100%;
}

.b-skeleton-animate-wave::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
  animation: b-skeleton-animate-wave 1.75s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-wave::after {
    background: none;
    animation: none;
  }
}

@keyframes b-skeleton-animate-wave {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
.b-skeleton-animate-fade {
  animation: b-skeleton-animate-fade 0.875s ease-in-out alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-fade {
    animation: none;
  }
}

@keyframes b-skeleton-animate-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.b-skeleton-animate-throb {
  animation: b-skeleton-animate-throb 0.875s ease-in alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-throb {
    animation: none;
  }
}

@keyframes b-skeleton-animate-throb {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.975);
  }
}
.table.b-table.b-table-fixed {
  table-layout: fixed;
}
.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}
.table.b-table[aria-busy=true] {
  opacity: 0.55;
}
.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}
.table.b-table > caption {
  caption-side: bottom;
}
.table.b-table.b-table-caption-top > caption {
  caption-side: top !important;
}
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table.b-table.table-hover > tbody > tr.table-active:hover td,
.table.b-table.table-hover > tbody > tr.table-active:hover th {
  color: #1a1a1a;
  background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
  background-repeat: no-repeat;
}
.table.b-table > tbody > .bg-active,
.table.b-table > tbody > .bg-active > th,
.table.b-table > tbody > .bg-active > td {
  background-color: rgba(255, 255, 255, 0.075) !important;
}
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover td,
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover th {
  color: #fff;
  background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
  background-repeat: no-repeat;
}

.b-table-sticky-header,
.table-responsive,
[class*=table-responsive-] {
  margin-bottom: 1rem;
}
.b-table-sticky-header > .table,
.table-responsive > .table,
[class*=table-responsive-] > .table {
  margin-bottom: 0;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px;
}

@media print {
  .b-table-sticky-header {
    overflow-y: visible !important;
    max-height: none !important;
  }
}
@supports (position: sticky) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: sticky;
    left: 0;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5;
  }
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2;
  }
  .table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    color: #1a1a1a;
    background-color: #fff;
  }
  .table.b-table.table-dark > thead > tr > .bg-b-table-default,
  .table.b-table.table-dark > tbody > tr > .bg-b-table-default,
  .table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #232323;
  }
  .table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    background-repeat: no-repeat;
  }
  .table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(odd) > .bg-b-table-default {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-repeat: no-repeat;
  }
  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #1a1a1a;
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    background-repeat: no-repeat;
  }
  .table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
    background-repeat: no-repeat;
  }
}
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.75rem / 2) center;
  padding-right: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.75rem / 2) center;
  padding-left: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort=none],
.table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort=ascending],
.table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort=descending],
.table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=none], .table.b-table.table-dark > tfoot > tr > [aria-sort=none],
.table.b-table > .thead-dark > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=ascending], .table.b-table.table-dark > tfoot > tr > [aria-sort=ascending],
.table.b-table > .thead-dark > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=descending], .table.b-table.table-dark > tfoot > tr > [aria-sort=descending],
.table.b-table > .thead-dark > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=none],
.table.b-table > tfoot > tr > .table-dark[aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=ascending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=descending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3rem / 2) center;
  padding-left: calc(0.3rem + 0.65em);
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  cursor: pointer;
}
.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range > tbody > tr {
  user-select: none;
}

@media (max-width: 575.98px) {
  .table.b-table.b-table-stacked-sm {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-sm > caption,
  .table.b-table.b-table-stacked-sm > tbody,
  .table.b-table.b-table-stacked-sm > tbody > tr,
  .table.b-table.b-table-stacked-sm > tbody > tr > td,
  .table.b-table.b-table-stacked-sm > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-sm > thead,
  .table.b-table.b-table-stacked-sm > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-sm > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr.top-row, .table.b-table.b-table-stacked-sm > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-md > caption,
  .table.b-table.b-table-stacked-md > tbody,
  .table.b-table.b-table-stacked-md > tbody > tr,
  .table.b-table.b-table-stacked-md > tbody > tr > td,
  .table.b-table.b-table-stacked-md > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-md > thead,
  .table.b-table.b-table-stacked-md > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-md > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-md > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-md > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-md > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-md > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr.top-row, .table.b-table.b-table-stacked-md > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 991.98px) {
  .table.b-table.b-table-stacked-lg {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-lg > caption,
  .table.b-table.b-table-stacked-lg > tbody,
  .table.b-table.b-table-stacked-lg > tbody > tr,
  .table.b-table.b-table-stacked-lg > tbody > tr > td,
  .table.b-table.b-table-stacked-lg > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-lg > thead,
  .table.b-table.b-table-stacked-lg > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-lg > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr.top-row, .table.b-table.b-table-stacked-lg > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 1259.98px) {
  .table.b-table.b-table-stacked-xl {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-xl > caption,
  .table.b-table.b-table-stacked-xl > tbody,
  .table.b-table.b-table-stacked-xl > tbody > tr,
  .table.b-table.b-table-stacked-xl > tbody > tr > td,
  .table.b-table.b-table-stacked-xl > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-xl > thead,
  .table.b-table.b-table-stacked-xl > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-xl > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr.top-row, .table.b-table.b-table-stacked-xl > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
.table.b-table.b-table-stacked {
  display: block;
  width: 100%;
}
.table.b-table.b-table-stacked > caption,
.table.b-table.b-table-stacked > tbody,
.table.b-table.b-table-stacked > tbody > tr,
.table.b-table.b-table-stacked > tbody > tr > td,
.table.b-table.b-table-stacked > tbody > tr > th {
  display: block;
}
.table.b-table.b-table-stacked > thead,
.table.b-table.b-table-stacked > tfoot {
  display: none;
}
.table.b-table.b-table-stacked > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-bottom-row {
  display: none;
}
.table.b-table.b-table-stacked > caption {
  caption-side: top !important;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  content: attr(data-label);
  width: 40%;
  float: left;
  text-align: right;
  overflow-wrap: break-word;
  font-weight: bold;
  font-style: normal;
  padding: 0 calc(1rem / 2) 0 0;
  margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::after {
  display: block;
  clear: both;
  content: "";
}
.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  display: inline-block;
  width: calc(100% - 40%);
  padding: 0 0 0 calc(1rem / 2);
  margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr.top-row, .table.b-table.b-table-stacked > tbody > tr.bottom-row {
  display: none;
}
.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 3px;
}
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
  border-top-width: 3px;
}

.b-time {
  min-width: 150px;
}
.b-time[aria-disabled=true] output, .b-time[aria-readonly=true] output,
.b-time output.disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.b-time[aria-disabled=true] output {
  pointer-events: none;
}
[dir=rtl] .b-time > .d-flex:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-time .b-time-header {
  margin-bottom: 0.5rem;
}
.b-time .b-time-header output {
  padding: 0.25rem;
  font-size: 80%;
}
.b-time .b-time-footer {
  margin-top: 0.5rem;
}
.b-time .b-time-ampm {
  margin-left: 0.5rem;
}

.b-toast {
  display: block;
  position: relative;
  max-width: 350px;
  backface-visibility: hidden;
  background-clip: padding-box;
  z-index: 1;
}
.b-toast .toast {
  background-color: rgba(255, 255, 255, 0.85);
}
.b-toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.b-toast.b-toast-solid .toast {
  background-color: white;
}
.b-toast .toast {
  opacity: 1;
}
.b-toast .toast.fade:not(.show) {
  opacity: 0;
}
.b-toast .toast .toast-body {
  display: block;
}

.b-toast-primary .toast {
  background-color: rgba(227, 230, 233, 0.85);
  border-color: rgba(196, 202, 209, 0.85);
  color: #18222e;
}
.b-toast-primary .toast .toast-header {
  color: #18222e;
  background-color: rgba(213, 217, 222, 0.85);
  border-bottom-color: rgba(196, 202, 209, 0.85);
}
.b-toast-primary.b-toast-solid .toast {
  background-color: #e3e6e9;
}

.b-toast-secondary .toast {
  background-color: rgba(253, 234, 234, 0.85);
  border-color: rgba(249, 194, 193, 0.85);
  color: #791312;
}
.b-toast-secondary .toast .toast-header {
  color: #791312;
  background-color: rgba(251, 211, 211, 0.85);
  border-bottom-color: rgba(249, 194, 193, 0.85);
}
.b-toast-secondary.b-toast-solid .toast {
  background-color: #fdeaea;
}

.b-toast-success .toast {
  background-color: rgba(236, 252, 235, 0.85);
  border-color: rgba(197, 246, 196, 0.85);
  color: #197417;
}
.b-toast-success .toast .toast-header {
  color: #197417;
  background-color: rgba(214, 249, 213, 0.85);
  border-bottom-color: rgba(197, 246, 196, 0.85);
}
.b-toast-success.b-toast-solid .toast {
  background-color: #ecfceb;
}

.b-toast-info .toast {
  background-color: rgba(237, 240, 245, 0.85);
  border-color: rgba(207, 216, 228, 0.85);
  color: #2b3d53;
}
.b-toast-info .toast .toast-header {
  color: #2b3d53;
  background-color: rgba(220, 227, 236, 0.85);
  border-bottom-color: rgba(207, 216, 228, 0.85);
}
.b-toast-info.b-toast-solid .toast {
  background-color: #edf0f5;
}

.b-toast-warning .toast {
  background-color: rgba(255, 249, 231, 0.85);
  border-color: rgba(255, 238, 186, 0.85);
  color: #856404;
}
.b-toast-warning .toast .toast-header {
  color: #856404;
  background-color: rgba(255, 243, 205, 0.85);
  border-bottom-color: rgba(255, 238, 186, 0.85);
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fff9e7;
}

.b-toast-danger .toast {
  background-color: rgba(253, 234, 234, 0.85);
  border-color: rgba(249, 194, 193, 0.85);
  color: #791312;
}
.b-toast-danger .toast .toast-header {
  color: #791312;
  background-color: rgba(251, 211, 211, 0.85);
  border-bottom-color: rgba(249, 194, 193, 0.85);
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fdeaea;
}

.b-toast-light .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(252, 252, 252, 0.85);
  color: #7f7f7f;
}
.b-toast-light .toast .toast-header {
  color: #7f7f7f;
  background-color: rgba(253, 253, 253, 0.85);
  border-bottom-color: rgba(252, 252, 252, 0.85);
}
.b-toast-light.b-toast-solid .toast {
  background-color: white;
}

.b-toast-dark .toast {
  background-color: rgba(224, 224, 224, 0.85);
  border-color: rgba(193, 193, 193, 0.85);
  color: #121212;
}
.b-toast-dark .toast .toast-header {
  color: #121212;
  background-color: rgba(211, 211, 211, 0.85);
  border-bottom-color: rgba(193, 193, 193, 0.85);
}
.b-toast-dark.b-toast-solid .toast {
  background-color: #e0e0e0;
}

.b-toaster {
  z-index: 1100;
}
.b-toaster .b-toaster-slot {
  position: relative;
  display: block;
}
.b-toaster .b-toaster-slot:empty {
  display: none !important;
}

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  position: absolute;
  max-width: 350px;
  width: 100%; /* IE 11 fix */
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-top-full .b-toaster-slot .toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
  top: 0;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 0.5rem;
}
.b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  bottom: 0;
}
.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: 0.5rem;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto;
}
.b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-move, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: transform 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  position: absolute;
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
  transition-delay: 0s;
}
.tooltip.b-tooltip {
  display: block;
  opacity: 1;
  outline: 0;
}
.tooltip.b-tooltip.fade:not(.show) {
  opacity: 0;
}
.tooltip.b-tooltip.show {
  opacity: 1;
}
.tooltip.b-tooltip.noninteractive {
  pointer-events: none;
}
.tooltip.b-tooltip .arrow {
  margin: 0 0.25rem;
}
.tooltip.b-tooltip.bs-tooltip-right .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip.bs-tooltip-left .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=left] .arrow {
  margin: 0.25rem 0;
}

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #2e4159;
}
.tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #2e4159;
}
.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #2e4159;
}
.tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #2e4159;
}
.tooltip.b-tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #2e4159;
}

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #e92422;
}
.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #e92422;
}
.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #e92422;
}
.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #e92422;
}
.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #fff;
  background-color: #e92422;
}

.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #31e02d;
}
.tooltip.b-tooltip-success.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #31e02d;
}
.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #31e02d;
}
.tooltip.b-tooltip-success.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #31e02d;
}
.tooltip.b-tooltip-success .tooltip-inner {
  color: #1a1a1a;
  background-color: #31e02d;
}

.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #52759f;
}
.tooltip.b-tooltip-info.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #52759f;
}
.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #52759f;
}
.tooltip.b-tooltip-info.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #52759f;
}
.tooltip.b-tooltip-info .tooltip-inner {
  color: #fff;
  background-color: #52759f;
}

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #ffc107;
}
.tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #ffc107;
}
.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #ffc107;
}
.tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #ffc107;
}
.tooltip.b-tooltip-warning .tooltip-inner {
  color: #1a1a1a;
  background-color: #ffc107;
}

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #e92422;
}
.tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #e92422;
}
.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #e92422;
}
.tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #e92422;
}
.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #e92422;
}

.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f4f4f4;
}
.tooltip.b-tooltip-light.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f4f4f4;
}
.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f4f4f4;
}
.tooltip.b-tooltip-light.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f4f4f4;
}
.tooltip.b-tooltip-light .tooltip-inner {
  color: #1a1a1a;
  background-color: #f4f4f4;
}

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #232323;
}
.tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #232323;
}
.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #232323;
}
.tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #232323;
}
.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #232323;
}

.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  animation: 2s infinite linear normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
    animation: none;
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(-25%);
  }
}
@keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}

@font-face {
  font-display: swap;
  font-family: "GenerisSerif";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/GenerisSerifCom-Bold.ttf);
}
@font-face {
  font-display: swap;
  font-family: "GenerisSerif";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/GenerisSerifCom-Regular.ttf);
}
@font-face {
  font-display: swap;
  font-family: "GenerisSans";
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/GenerisSansCom-Light.ttf);
}
@font-face {
  font-display: swap;
  font-family: "GenerisSans";
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/GenerisSansCom-Medium.ttf);
}
@font-face {
  font-display: swap;
  font-family: "GenerisSans";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/GenerisSansCom-Regular.ttf);
}
@font-face {
  font-display: swap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/poppins-v20-latin-regular.woff2) format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/poppins-v20-latin-500.woff2) format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/poppins-v20-latin-700.woff2) format("woff2");
}
html {
  font-size: 17.6px;
}

h1, .h1 {
  font-family: "GenerisSerif";
  line-height: 1;
  letter-spacing: 1.37px;
  color: #e92422;
  font-weight: 700;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
h1.text-headline, .h1.text-headline {
  font-family: "GenerisSans", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1a1a1a;
  text-align: center;
}
@media (min-width: 576px) {
  h1, .h1 {
    text-align: left;
  }
}
@media (min-width: 992px) {
  h1, .h1 {
    font-size: 4rem;
    letter-spacing: 2.43px;
    margin-bottom: 30px;
  }
  h1.text-headline, .h1.text-headline {
    font-size: 3.125rem;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: 1.2;
  }
}

h2, .h2 {
  line-height: 1.35;
  letter-spacing: 0.81px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
@media (min-width: 992px) {
  h2, .h2 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.21875;
    letter-spacing: 1.3px;
  }
}

h3, .h3 {
  font-weight: 300;
  line-height: 1.368421053;
  letter-spacing: 0.02px;
  margin-bottom: 0.75rem;
}
@media (min-width: 992px) {
  h3, .h3 {
    font-size: 1.375rem;
    line-height: 1.409090909;
  }
}

.text-emphasized {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.hyphens {
  word-wrap: break-word;
  hyphens: auto;
}

.custom-select,
.form-control {
  font-size: 16px;
  font-family: "Poppins", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@media (min-width: 992px) {
  .custom-select,
  .form-control {
    padding: var(--ba-input-padding-y-lg) var(--ba-input-padding-x-lg);
    min-height: var(--ba-input-min-height-lg);
  }
}

.form-group > label,
.form-label {
  color: #626262;
  font-size: 18px;
  font-family: "Poppins", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  line-height: 1.5;
  hyphens: auto;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background-color: #52759f;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}
input[type=range]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background-color: #52759f;
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]::-ms-thumb {
  width: 25px;
  height: 25px;
  background-color: #52759f;
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-moz-range-progress {
  background: #52759f;
}
input[type=range]::-webkit-progress-value {
  background: #52759f;
}

.control-label-overlay {
  position: relative;
}
.control-label-overlay .control-label {
  display: block;
  white-space: nowrap;
  width: calc(100% - 50px);
  pointer-events: none;
  cursor: pointer;
  background-color: #fff;
  padding: 6px 5px 10px 5px;
  color: #b0b0b0;
  font-size: 1rem;
  line-height: 1;
  position: absolute;
  z-index: 10;
  top: 11px;
  left: 20px;
  overflow: hidden;
  transition: font-size 0.2s linear, top 0.2s linear, background-color 0.1s linear 0.15s, padding-bottom 0.2s linear;
  text-overflow: ellipsis;
  margin-bottom: 0;
  text-align: left;
}
.control-label-overlay.label-auto-width .control-label {
  width: auto;
}
.control-label-overlay .form-control {
  padding-top: 22px;
  padding-bottom: 6px;
}
.control-label-overlay .form-control::placeholder {
  color: #fff;
  opacity: 0;
}
.control-label-overlay .js-advanced-select + .control-label {
  pointer-events: none;
}
.control-label-overlay.has-value .control-label,
.control-label-overlay .form-control:active + .control-label,
.control-label-overlay .form-control:focus + .control-label {
  color: #2e4159;
  background-color: transparent;
  padding-bottom: 2px;
  letter-spacing: 0.53px;
  top: 3px;
  font-size: 7px;
}

.ba-radio-group {
  --ba-rg-height: 45px;
  --ba-rg-line-height: 25px;
  --ba-rg-padding: 11px 10px 9px;
  --ba-rg-border-radius: 12px;
  --ba-rg-min-width-xs: 110px;
  --ba-rg-min-width-sm: 110px;
}
.ba-radio-group.small {
  --ba-rg-height: 36px;
  --ba-rg-line-height: 22px;
  --ba-rg-padding: 8px 8px 4px;
  --ba-rg-border-radius: 8px;
  --ba-rg-min-width-xs: 80px;
  --ba-rg-min-width-sm: 80px;
}
.ba-radio-group.min-width-sm {
  --ba-rg-min-width-xs: 0;
}
.ba-radio-group .custom-control {
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  flex: 1 1 auto;
  margin-right: 0;
  padding-left: 0;
  min-height: 0;
  overflow: hidden;
}
.ba-radio-group .custom-control label {
  font-family: "Poppins", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: pointer;
  display: block;
  height: var(--ba-rg-height);
  line-height: var(--ba-rg-line-height);
  text-align: center;
  padding: var(--ba-rg-padding);
  width: 100%;
  transition: color 0.2s linear;
}
.ba-radio-group .custom-control label::before {
  display: none;
}
.ba-radio-group .custom-control label span {
  position: relative;
  z-index: 10;
}
.ba-radio-group .custom-control:first-child {
  border-top-left-radius: var(--ba-rg-border-radius);
  border-bottom-left-radius: var(--ba-rg-border-radius);
}
.ba-radio-group .custom-control:last-child {
  border-top-right-radius: var(--ba-rg-border-radius);
  border-bottom-right-radius: var(--ba-rg-border-radius);
  border-right-width: 1px;
}
.ba-radio-group .custom-control .custom-control-input:checked ~ .custom-control-label {
  color: #fff;
}
.ba-radio-group .custom-control .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  display: block;
  left: 0;
  width: 100%;
  height: 100%;
  background: #52759f;
  z-index: 0;
  top: 0;
  position: absolute;
}
@media (min-width: 360px) {
  .ba-radio-group .custom-control {
    min-width: var(--ba-rg-min-width-xs);
  }
}
@media (min-width: 576px) {
  .ba-radio-group .custom-control {
    min-width: var(--ba-rg-min-width-sm);
  }
}

.form-errors {
  margin: 20px 0;
}

.ba-checkbox-group {
  margin-bottom: -15px;
}
.ba-checkbox-group .custom-control {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  flex: 1 1 auto;
  margin-bottom: 15px;
  padding-left: 0;
  overflow: hidden;
  width: 100px;
}
.ba-checkbox-group .custom-control:not(:last-child) {
  margin-right: 16px;
}
.ba-checkbox-group .custom-control label {
  cursor: pointer;
  display: block;
  height: 45px;
  line-height: 25px;
  text-align: center;
  padding: 12px 10px 8px;
  width: 100%;
  transition: color 0.2s linear;
}
.ba-checkbox-group .custom-control label::before {
  display: none;
}
.ba-checkbox-group .custom-control label span {
  position: relative;
  z-index: 10;
}
.ba-checkbox-group .custom-control .custom-control-input:checked ~ .custom-control-label {
  color: #fff;
}
.ba-checkbox-group .custom-control .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  display: block;
  left: 0;
  width: 100%;
  height: 100%;
  background: #52759f;
  z-index: 0;
  top: 0;
  position: absolute;
}
@media (min-width: 360px) {
  .ba-checkbox-group:not(.min-width-sm) .custom-control {
    min-width: 110px;
  }
}
@media (min-width: 576px) {
  .ba-checkbox-group .custom-control {
    min-width: 110px;
  }
}

.ba-checkbox-group-lg .custom-checkbox {
  padding-left: 48px;
  min-height: 40px;
}
.ba-checkbox-group-lg .custom-control-label::before {
  width: 35px;
  height: 35px;
  border-color: #d8d8d8;
  left: -48px;
}
.ba-checkbox-group-lg .custom-control-label::after {
  width: 35px;
  height: 35px;
  left: -48px;
}
.ba-checkbox-group-lg .custom-control-input:checked ~ .custom-control-label::before {
  color: #52759f;
  border-color: #d8d8d8;
  background: transparent;
}
.ba-checkbox-group-lg .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%232e4159' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 22px 22px;
}

.ba-checkbox-group-default .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d8d8d8;
  background: transparent;
  left: -26px;
  width: 18px;
  height: 18px;
}
.ba-checkbox-group-default .custom-control-input:checked ~ .custom-control-label::after {
  top: 6.5px;
  left: -24px;
  width: 14px;
  height: 14px;
}

@media (max-width: 1259.98px) {
  .ba-checkbox-group-lg-down {
    margin-bottom: -15px;
  }
  .ba-checkbox-group-lg-down .custom-control {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    flex: 1 1 auto;
    margin-bottom: 15px;
    padding-left: 0;
    overflow: hidden;
    width: 100px;
  }
  .ba-checkbox-group-lg-down .custom-control:not(:last-child) {
    margin-right: 16px;
  }
  .ba-checkbox-group-lg-down .custom-control label {
    cursor: pointer;
    display: block;
    height: 45px;
    line-height: 25px;
    text-align: center;
    padding: 12px 10px 8px;
    width: 100%;
    transition: color 0.2s linear;
  }
  .ba-checkbox-group-lg-down .custom-control label::before {
    display: none;
  }
  .ba-checkbox-group-lg-down .custom-control label span {
    position: relative;
    z-index: 10;
  }
  .ba-checkbox-group-lg-down .custom-control .custom-control-input:checked ~ .custom-control-label {
    color: #fff;
  }
  .ba-checkbox-group-lg-down .custom-control .custom-control-input:checked ~ .custom-control-label::after {
    content: "";
    display: block;
    left: 0;
    width: 100%;
    height: 100%;
    background: #52759f;
    z-index: 0;
    top: 0;
    position: absolute;
  }
}
@media (min-width: 1260px) {
  .ba-checkbox-group-lg-down .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #d8d8d8;
    background: transparent;
    left: -26px;
    width: 18px;
    height: 18px;
  }
  .ba-checkbox-group-lg-down .custom-control-input:checked ~ .custom-control-label::after {
    top: 6.5px;
    left: -24px;
    width: 14px;
    height: 14px;
  }
}

.nav-tabs .nav-link.has-field-errors {
  font-weight: 700;
  color: #e92422;
}

.has-error .custom-select,
.has-error .form-control,
.has-error .multiselect__tags {
  border-color: #e92422;
  color: #e92422;
}
.has-error .invalid-feedback {
  display: block;
  font-size: 11px;
  line-height: 12px;
  color: #e92422;
  background-color: #fef4f4;
  border: 1px solid #e92422;
  padding: 8px 8px 4px 8px;
  position: relative;
  overflow: visible;
  margin-top: -1px;
}
.has-error .invalid-feedback::before, .has-error .invalid-feedback::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  background-color: #fef4f4;
  border-top: 1px solid #e92422;
  left: 20px;
  transform: rotate(-45deg);
  position: absolute;
  top: -5px;
  z-index: 1;
}
.has-error .invalid-feedback::after {
  background: transparent;
  left: 20px;
  transform: rotate(45deg);
  z-index: 2;
}
.has-error .custom-checkbox + .invalid-feedback {
  margin-top: 5px;
}

.vs__search {
  height: 35px;
}
@media (min-width: 992px) {
  .vs__search {
    height: 32px;
  }
}

.vs__dropdown-menu {
  font-size: 14px;
  max-width: 100%;
}
.vs__dropdown-menu > li {
  hyphens: auto;
  white-space: normal;
  line-height: 1.2;
}

.btn {
  text-transform: uppercase;
  border-radius: 23px;
  font-weight: 500;
  padding-bottom: 0.675rem;
}

.btn-full-width {
  width: 100%;
}

.btn-primary {
  background-color: #52759f;
}
.btn-primary:hover {
  color: #fff;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2e4159;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
@media (min-width: 992px) {
  .btn-primary {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 992px) {
  .btn-secondary {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.btn-sm {
  border-radius: 16px;
  letter-spacing: 0.1px;
}

.btn-link {
  border-radius: 0;
  transition: color 0.2s linear;
}

.btn-default {
  background: #d8d8d8;
  font-weight: 500;
  border-radius: 0;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  color: #2e4159;
  text-transform: uppercase;
}

.btn-light {
  border-color: #f4f4f4;
  color: #2e4159;
  transition: color 0.2s linear;
}
.btn-light:hover, .btn-light:focus, .btn-light:active {
  border-color: #f4f4f4;
  color: #e92422;
}

.btn-outline-primary {
  color: #52759f;
  background-color: white;
  border: 1px solid #52759f;
  transition: all 0.2s linear;
}
@media (min-width: 992px) {
  .btn-outline-primary {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.btn-lg {
  border-radius: 31px;
}

.ba-link-action span {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ba-link-action span::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  transition: all 0.2s ease-in-out;
  margin-top: -2px;
}
.ba-link-action:hover, .ba-link-action:focus {
  text-decoration: none;
}
.ba-link-action:hover span, .ba-link-action:focus span {
  color: #e92422;
  text-decoration: none;
}
.ba-link-action:hover span::after, .ba-link-action:focus span::after {
  width: 0;
  background-color: #e92422;
}

.has-text-icon {
  display: flex;
  align-items: center;
}

.icon-circle {
  flex: 0 0 21px;
  border-radius: 50%;
  display: block;
  width: 21px;
  height: 21px;
  text-align: center;
  line-height: 21px;
  margin-right: 10px;
}

.bg-icon-circle {
  background: #2e4159;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  text-align: center;
}
.bg-icon-circle svg {
  color: #fff;
  width: 25px;
  height: 25px;
}

.icon-add {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #b0b0b0;
  transition: all 0.2s ease-in-out;
  position: relative;
  margin-right: 9px;
}
.icon-add::before, .icon-add::after {
  content: "";
  background: #b0b0b0;
  border-radius: 2px;
  position: absolute;
  transition: all 0.2s ease-in-out;
}
.icon-add::before {
  width: 3px;
  height: 13px;
  left: 50%;
  top: 7px;
  margin-left: -1px;
}
.icon-add::after {
  width: 13px;
  height: 3px;
  left: 7px;
  top: 50%;
  margin-top: -1px;
}

.has-text-icon:hover .icon-add, .has-text-icon:focus .icon-add {
  border-color: #e92422;
}
.has-text-icon:hover .icon-add::before, .has-text-icon:hover .icon-add::after, .has-text-icon:focus .icon-add::before, .has-text-icon:focus .icon-add::after {
  background: #e92422;
}

.icon-angle {
  display: inline-block;
  position: relative;
}
.icon-angle::before, .icon-angle::after {
  content: "";
  background: #1a1a1a;
  display: block;
  width: 2px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s linear;
}
.icon-angle::before {
  transform: rotate(-50deg);
  top: -3px;
}
.icon-angle::after {
  transform: rotate(50deg);
  top: 3px;
}
.icon-angle.down {
  width: 8px;
  height: 14px;
}
.icon-angle.down::before {
  top: 0;
  transform: rotate(45deg);
}
.icon-angle.down::after {
  top: 0;
  left: 0;
  transform: rotate(-45deg);
}

.ba-green {
  color: #73c753;
}

.col-icon-fit {
  flex-grow: 0;
}

.pagination {
  margin-bottom: 0;
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  transition: all 0.2s ease-in-out;
  line-height: 1.25;
  padding: 7px 0 6px;
  min-width: 30px;
  text-align: center;
}

.page-nav {
  border-width: 0;
}

.page-item {
  margin: 0 5px;
}
.page-item:first-child .page-link, .page-item:last-child .page-link {
  border-width: 0;
}
.page-item:first-child .page-link svg, .page-item:last-child .page-link svg {
  margin-top: -2px;
}
.page-item:first-child .page-link:hover, .page-item:first-child .page-link:focus, .page-item:first-child .page-link:active, .page-item:last-child .page-link:hover, .page-item:last-child .page-link:focus, .page-item:last-child .page-link:active {
  background: transparent;
  color: #3c536c;
  text-decoration: underline;
}
.page-item:first-child .page-link svg {
  margin-right: 6px;
}
.page-item:last-child .page-link svg {
  margin-left: 6px;
}
.page-item.disabled .page-link {
  border-width: 0;
}
.page-item.disabled:first-child {
  opacity: 0;
}
.page-item.disabled:last-child {
  opacity: 0;
}
@media (min-width: 992px) {
  .page-item.active .page-link {
    border-color: #2e4159;
    color: #fff;
    background: #2e4159;
  }
}

.modal-header {
  background: #2e4159;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
}
.modal-header .modal-title {
  color: #fff;
}
.modal-header .close {
  position: relative;
  margin: 0;
  padding: 0;
  opacity: 1;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
}
.modal-header .close::before, .modal-header .close::after {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  left: 13px;
  top: 0;
  width: 2px;
  height: 27px;
  transform: rotate(45deg);
}
.modal-header .close::after {
  transform: rotate(-45deg);
}

.modal-body {
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
}

.modal-footer {
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
}

.tooltip-inner {
  z-index: 1;
  border: 1px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  line-height: 1.2;
  position: relative;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] {
  padding-left: 0.75rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-width: 0;
  background: #fff;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  top: 1px;
  right: auto;
  left: 10px;
  z-index: 3;
}
.bs-tooltip-right .arrow::after, .bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::after, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::after {
  content: "";
  background: #fff;
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  top: 1px;
  left: 8px;
  border: 1px solid #d8d8d8;
  transform: rotate(-45deg);
  z-index: 0;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 #d8d8d8;
}

@media (min-width: 992px) {
  .ps--active-y .ps__rail-y {
    background-color: #d8d8d8;
    width: 5px;
  }
  .ps--active-y .ps__rail-y .ps__thumb-y {
    background-color: #52759f;
    border-radius: 0;
    width: 5px;
    right: 0;
  }
  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    opacity: 1;
  }
}
.vdp-datepicker .vdp-datepicker__clear-button {
  position: absolute;
  top: calc(50% - 15px);
  color: #626262;
  right: 7px;
  z-index: 10;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  font-family: arial, helvetica, sans-serif;
}

.month-picker-input-container {
  z-index: 20;
  width: 200px;
}
.month-picker-input-container .month-picker__container {
  border: 1px solid #b0b0b0;
}
.month-picker-input-container .month-picker__year {
  box-shadow: none;
  border-bottom: 1px solid #b0b0b0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.month-picker-input-container .month-picker__month.selected, .month-picker-input-container .month-picker__month.selected-range-first, .month-picker-input-container .month-picker__month.selected-range-second {
  background-color: #52759f;
  box-shadow: none;
  text-shadow: none;
}
.month-picker-input-container .month-picker-input {
  display: block;
  width: 100%;
  height: calc(1.3125em + 1.375rem + 2px);
  padding: 0.6875rem 0.875rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.3125;
  color: #626262;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d8d8 !important;
  border-radius: 0 !important;
}
.month-picker-input-container .month-picker-input:disabled, .month-picker-input-container .month-picker-input[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
@media (min-width: 992px) {
  .month-picker-input-container .month-picker-input {
    padding: 0.75rem 0.75rem;
    min-height: var(--ba-input-min-height-lg);
  }
}

@keyframes vms-spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  z-index: 20;
  display: block;
}
.multiselect__spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
  animation: vms-spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
  animation: vms-spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.multiselect__loading-enter {
  opacity: 0;
}

.multiselect {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #626262;
}
.multiselect:focus {
  outline: none;
}

.multiselect__input {
  font-family: inherit;
  font-size: 1rem;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  position: relative;
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  border: none;
  border-radius: 0;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
  vertical-align: top;
}
.multiselect__input::placeholder {
  color: #b0b0b0;
  opacity: 1;
}
.multiselect__input:focus {
  outline: none;
}

.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border-radius: 5px;
  background: #fff;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  vertical-align: top;
  padding-top: 1px;
}
.multiselect__single:focus {
  outline: none;
}

.multiselect--disabled {
  background: #e9ecef;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--disabled .multiselect__current {
  background: #e9ecef;
  color: #a6a6a6;
}
.multiselect--disabled .multiselect__select {
  background: #e9ecef;
  color: #a6a6a6;
}

.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active:not(.multiselect--above) .multiselect__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #626262;
  background-color: #fff;
  border-color: #5c7fab;
}
.multiselect--active .multiselect__select:before {
  transform: rotate(135deg);
}
.multiselect--active .multiselect__select::after {
  transform: rotate(-135deg);
}
.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect--above.multiselect--active .multiselect__current {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect--above.multiselect--active .multiselect__input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #626262;
  background-color: #fff;
  border-color: #5c7fab;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #2e4159;
  margin-bottom: 5px;
  white-space: wrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: 14px;
}
.multiselect__tag ~ .multiselect__input {
  width: auto;
}
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__tag:last-child {
  margin-bottom: 0;
}

.multiselect__tags-wrap {
  display: inline;
  max-width: 100%;
}

.multiselect__tags {
  min-height: calc(1.3125em + 1.375rem + 2px);
  display: block;
  background-color: #fff;
  border-radius: 0;
  font-size: 16px;
  font-family: "Poppins", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1;
  padding: 0.6875rem 40px 3px 0.875rem;
  background-clip: padding-box;
  border: 1px solid #d8d8d8;
}
@media (min-width: 992px) {
  .multiselect__tags {
    padding: var(--ba-multiselect-tags-padding-top) 40px 6px var(--ba-input-padding-x-lg);
    min-height: var(--ba-input-min-height-lg);
    display: flex;
    align-items: center;
  }
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "×";
  color: #fff;
  font-size: 14px;
  transition: color 0.2s linear;
}
.multiselect__tag-icon:hover:after, .multiselect__tag-icon:focus:after {
  color: #8abce6;
}

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: calc(50% - 19px);
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select::before, .multiselect__select::after {
  content: "";
  background: #d8d8d8;
  display: block;
  width: 2px;
  height: 10px;
  position: absolute;
  transition: all 0.2s linear;
  top: calc(50% - 4px);
  margin-top: 0;
  z-index: 10;
}
.multiselect__select:before {
  transform: rotate(45deg);
  right: 10px;
}
.multiselect__select::after {
  left: 22px;
  transform: rotate(-45deg);
}
.multiselect__select svg {
  display: none;
}

.multiselect__placeholder {
  color: #b0b0b0;
  display: inline-block;
  padding-top: 3px;
  max-width: 100%;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  font-size: 16px;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #5c7fab;
  border-top: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 0.5rem;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: #2e4159;
  outline: none;
  color: #fff;
}

.multiselect__option--selected {
  display: none;
}

.single-choice .multiselect__option--selected {
  background: #f4f4f4;
  color: #626262;
  display: block;
}
.single-choice .multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.single-choice.no-empty .multiselect__option--selected.multiselect__option--highlight {
  background: #2e4159;
  color: #fff;
}

.multiselect__option--disabled {
  background: #e9ecef !important;
  color: #b0b0b0 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #e9ecef;
  color: #52759f;
  font-weight: 500;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #2e4159;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #2e4159;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.no-empty .multiselect__option--group-selected.multiselect__option--highlight {
  background: #2e4159;
  color: #fff;
}

.multiselect-enter-active {
  transition: all 0.15s ease;
}

.multiselect-leave-active {
  transition: all 0.15s ease;
  opacity: 0;
}

.multiselect-enter {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

[dir=rtl] .multiselect {
  text-align: right;
}
[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir=rtl] .multiselect__content {
  text-align: right;
}
[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px;
}

body {
  --sp1: "@";
  --sp2: ".";
}

a span.sprotect span, a span.sprotect.mitigated::before {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
a span.sprotect.sp1::before {
  content: var(--sp1);
}
a span.sprotect.sp2::before {
  content: var(--sp2);
}

.leaflet-default-shadow-path {
  background-image: url(../media/marker-shadow.png);
}

.cc-theme-custom {
  font-family: "GenerisSans", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.75rem !important;
  line-height: 1.2 !important;
  opacity: 1;
  transition: opacity 1s ease;
  color: #1a1a1a;
  background: #f4f4f4;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  border-top: 2px solid #52759f !important;
  z-index: 9999;
}
@media (min-width: 768px) {
  .cc-theme-custom {
    font-size: 1rem !important;
    line-height: 1.5em !important;
  }
}
.cc-theme-custom.cc-invisible {
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}
.cc-theme-custom .cc-link,
.cc-theme-custom .cc-revoke:hover {
  text-decoration: underline;
}
.cc-theme-custom.ba-cc-sticky {
  position: fixed;
}
@supports (position: sticky) or (position: -webkit-sticky) {
  .cc-theme-custom.ba-cc-sticky {
    position: sticky;
  }
  .cc-theme-custom.ba-cc-sticky.cc-invisible {
    position: fixed;
  }
}
.cc-theme-custom.cc-banner {
  border-top: none;
  padding: 1em 0;
}
.cc-theme-custom .cc-revoke {
  padding: 0.5em;
}
.cc-theme-custom .cc-header {
  font-size: 1.25rem;
  font-weight: 700;
}
.cc-theme-custom .cc-link {
  color: #1a1a1a;
  opacity: 1;
  display: inline-block;
  text-decoration: underline;
  transition: color 0.2s linear;
}
.cc-theme-custom .cc-link:hover, .cc-theme-custom .cc-link:focus, .cc-theme-custom .cc-link:active {
  color: #1a1a1a;
  text-decoration: none;
}
.cc-theme-custom .cc-link:visited {
  color: #1a1a1a;
}
.cc-theme-custom .cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75;
}
.cc-theme-custom .cc-close:hover {
  opacity: 1;
}
.cc-theme-custom .cc-btn {
  background: #b0b0b0;
  color: #2e4159;
  border-width: 1px;
  transition: all 0.2s linear;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 0.9em;
  padding: 0.4em 0.8em 0.3em;
}
.cc-theme-custom .cc-btn:hover, .cc-theme-custom .cc-btn:focus, .cc-theme-custom .cc-btn:visited {
  background: #fff;
  border-color: #2e4159;
  color: #2e4159;
  text-decoration: none;
}
.cc-theme-custom .cc-btn:focus {
  outline: none;
}
.cc-theme-custom .cc-save {
  display: inline-block;
  white-space: nowrap;
}
.cc-theme-custom .cc-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 240px;
  margin: 10px auto;
}
.cc-theme-custom .cc-actions .cc-btn {
  display: block;
}
.cc-theme-custom .cc-actions .cc-btn:not(:last-child) {
  margin-bottom: 15px !important;
}
.cc-theme-custom .cc-actions .cc-btn.cc-save, .cc-theme-custom .cc-actions .cc-btn.cc-btn-settings {
  padding: 0.85em 1.4em 0.55em;
}
.cc-theme-custom .cc-actions .cc-save-all {
  border-color: #52759f;
  background-color: #52759f;
  color: #fff;
  transition: all 0.2s linear;
}
.cc-theme-custom .cc-actions .cc-save-all:hover, .cc-theme-custom .cc-actions .cc-save-all:focus {
  background-color: #2e4159;
  border-color: #2e4159;
  color: #fff;
}
.cc-theme-custom .cc-actions .cc-btn-settings, .cc-theme-custom .cc-actions .cc-btn-settings-save {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
  color: #fff;
  transition: all 0.2s linear;
}
.cc-theme-custom .cc-actions .cc-btn-settings:hover, .cc-theme-custom .cc-actions .cc-btn-settings:focus, .cc-theme-custom .cc-actions .cc-btn-settings-save:hover, .cc-theme-custom .cc-actions .cc-btn-settings-save:focus {
  background-color: #2e4159;
  border-color: #2e4159;
  color: #fff;
}
@media (min-width: 420px) {
  .cc-theme-custom .cc-actions {
    flex-direction: row;
    max-width: none;
    width: auto;
  }
  .cc-theme-custom .cc-actions .cc-btn {
    min-width: 125px;
    margin-bottom: 0;
    width: auto !important;
  }
  .cc-theme-custom .cc-actions .cc-btn:not(:last-child) {
    margin-bottom: 0 !important;
    margin-right: 15px !important;
  }
  .cc-theme-custom .cc-actions .cc-btn-settings {
    position: absolute;
    left: 0;
  }
}
@media (min-width: 768px) {
  .cc-theme-custom .cc-actions {
    margin: 20px 0 0;
  }
}
@media screen and (min-width: 1200px) {
  .cc-theme-custom .cc-actions {
    margin: 0;
  }
  .cc-theme-custom .cc-actions .cc-btn {
    justify-content: right;
  }
}
.cc-theme-custom .cc-categories {
  padding-left: 0;
  position: absolute;
  height: 0;
  overflow: hidden;
  transition: opacity 0.2s linear;
  opacity: 0;
  margin: 0 auto;
}
.cc-theme-custom .cc-categories .cc-btn {
  cursor: pointer;
  padding-left: 34px;
  text-align: left;
  border-color: #b0b0b0;
  background: none;
  position: relative;
  overflow: hidden;
}
.cc-theme-custom .cc-categories .cc-btn:not(.cc-info):not(.cc-save) {
  padding-left: 0;
  text-align: left;
}
.cc-theme-custom .cc-categories .cc-btn:not(.cc-info):not(.cc-save) .cc-label {
  padding: 6px 0 4px 38px;
  display: block;
  line-height: 1.2;
  font-weight: normal;
  color: #b0b0b0;
}
.cc-theme-custom .cc-categories .cc-btn .cc-btn-checkbox {
  box-sizing: border-box;
  display: block;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 6px;
  z-index: 0;
  outline: none;
  border: 1px solid #b0b0b0;
  width: 24px;
  height: 24px;
  line-height: 25.25px;
  color: #2e4159;
}
.cc-theme-custom .cc-categories .cc-btn input[type=checkbox] {
  height: 100%;
  width: 100%;
  z-index: 100;
  opacity: 0;
}
.cc-theme-custom .cc-categories .cc-btn input[type=checkbox]:checked + .cc-btn-checkbox:after {
  content: "✓";
  position: absolute;
  top: 0;
  left: 2px;
  font-size: 26px;
  text-shadow: 0 1px 3px rgba(150, 150, 150, 0.5);
}
.cc-theme-custom .cc-categories .cc-btn input[type=checkbox].cc-disabled + .cc-btn-checkbox {
  opacity: 0.6;
}
.cc-theme-custom .cc-categories .cc-info {
  padding-left: 8px;
  padding-right: 9px;
  border-right: 1px solid #b0b0b0;
  display: flex;
  align-items: center;
}
.cc-theme-custom .cc-categories .cc-category:hover .cc-btn, .cc-theme-custom .cc-categories .cc-category:focus .cc-btn, .cc-theme-custom .cc-categories .cc-category:visited .cc-btn {
  border-color: #2e4159;
}
.cc-theme-custom .cc-categories .cc-tooltip {
  color: #2e4159;
  background: #fff;
  border: none;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  width: 100%;
  position: absolute;
  left: 0;
}
@media screen and (min-width: 578px) {
  .cc-theme-custom .cc-categories {
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .cc-theme-custom .cc-categories {
    position: relative;
    height: auto;
    overflow: auto;
  }
}
.cc-theme-custom .cc-settings-show {
  display: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s linear;
}
@media (min-width: 420px) {
  .cc-theme-custom .cc-settings-show {
    position: relative;
  }
}
.cc-theme-custom.cc-show-settings .cc-categories {
  position: relative;
  visibility: visible;
  opacity: 1;
  z-index: 10;
  height: auto;
  overflow: visible;
}
.cc-theme-custom.cc-show-settings .cc-settings-show {
  display: inline-block;
  position: relative;
  visibility: visible;
  z-index: 1;
  opacity: 1;
}
.cc-theme-custom.cc-show-settings .cc-settings-hide {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
@media screen and (min-width: 1200px) {
  .cc-theme-custom.cc-show-settings .cc-settings-hide {
    display: inline-block;
  }
}
.cc-theme-custom .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.cc-theme-custom .form .cc-categories {
  order: 1;
}
.cc-theme-custom .form .cc-actions {
  order: 0;
}
@media (min-width: 768px) {
  .cc-theme-custom .form .cc-actions {
    margin-bottom: 20px;
  }
}
.cc-theme-custom .cc-category > .cc-btn:first-child {
  flex-grow: 1;
}
.cc-theme-custom.cc-floating {
  border: none;
  padding: 1em 1.8em !important;
  max-width: none !important;
}
.cc-theme-custom.cc-floating .cc-categories {
  display: none;
  flex-direction: column;
}
.cc-theme-custom.cc-floating .cc-categories .cc-category:not(:last-child) {
  margin-bottom: 10px;
}
.cc-theme-custom.cc-floating.cc-show-settings .cc-categories {
  display: flex;
}
.cc-theme-custom.cc-banner .form {
  max-width: none;
}
@media screen and (min-width: 1200px) {
  .cc-theme-custom.cc-banner .form {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 0;
  }
  .cc-theme-custom.cc-banner .form .cc-categories {
    order: 0;
  }
  .cc-theme-custom.cc-banner .form .cc-actions {
    order: 1;
    margin-bottom: 0;
  }
}
.cc-theme-custom .cc-icon-angle {
  display: block;
  overflow: visible;
  width: 20px;
  min-height: 20px;
  position: relative;
}
.cc-theme-custom .cc-icon-angle::before, .cc-theme-custom .cc-icon-angle::after {
  content: "";
  background: #b0b0b0;
  display: block;
  width: 1px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 3px;
  transition: all 0.2s linear;
}
.cc-theme-custom .cc-icon-angle::before {
  transform: rotate(-50deg);
  margin-left: 3px;
}
.cc-theme-custom .cc-icon-angle::after {
  transform: rotate(50deg);
  margin-left: -3px;
}
.cc-theme-custom .cc-info:hover .cc-icon-angle, .cc-theme-custom .cc-info:focus .cc-icon-angle, .cc-theme-custom .cc-info:active .cc-icon-angle {
  position: relative;
}
.cc-theme-custom .cc-info:hover .cc-icon-angle::before, .cc-theme-custom .cc-info:hover .cc-icon-angle::after, .cc-theme-custom .cc-info:focus .cc-icon-angle::before, .cc-theme-custom .cc-info:focus .cc-icon-angle::after, .cc-theme-custom .cc-info:active .cc-icon-angle::before, .cc-theme-custom .cc-info:active .cc-icon-angle::after {
  content: "";
  background: #2e4159;
  display: block;
  width: 2px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 3px;
  transition: all 0.2s linear;
}
.cc-theme-custom .cc-info:hover .cc-icon-angle::before, .cc-theme-custom .cc-info:focus .cc-icon-angle::before, .cc-theme-custom .cc-info:active .cc-icon-angle::before {
  transform: rotate(-50deg);
  margin-left: 3px;
}
.cc-theme-custom .cc-info:hover .cc-icon-angle::after, .cc-theme-custom .cc-info:focus .cc-icon-angle::after, .cc-theme-custom .cc-info:active .cc-icon-angle::after {
  transform: rotate(50deg);
  margin-left: -3px;
}
@media screen and (min-width: 768px) {
  .cc-theme-custom .cc-message {
    line-height: 1.2em;
  }
}
@media print {
  .cc-theme-custom.cc-revoke, .cc-theme-custom.cc-window {
    display: none;
  }
}
@media screen and (max-width: 790px) {
  .cc-theme-custom .cc-btn:not(.cc-info):not(.cc-save) {
    width: auto;
    max-width: none;
  }
}
@media screen and (max-width: 900px) {
  .cc-theme-custom .cc-btn {
    white-space: normal;
    hyphens: auto;
    word-break: break-word;
  }
}
@media screen and (max-width: 767px) {
  .cc-theme-custom.cc-top {
    top: 0;
  }
  .cc-theme-custom.cc-bottom {
    bottom: 0;
  }
  .cc-theme-custom.cc-banner, .cc-theme-custom.cc-left, .cc-theme-custom.cc-right {
    left: 0;
    right: 0;
  }
  .cc-theme-custom .cc-compliance {
    display: block;
    text-align: center;
    position: static;
    margin: 0;
    top: auto;
    width: auto;
    right: auto;
  }
  .cc-theme-custom .cc-compliance .cc-btn {
    display: inline-block;
  }
  .cc-theme-custom > .container {
    width: auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .cc-theme-custom > .container {
    width: auto;
  }
  .cc-theme-custom.cc-banner > .container {
    max-width: 996px;
    margin: 0 auto;
    position: relative;
  }
  .cc-theme-custom .cc-compliance {
    right: 31px;
  }
  .cc-theme-custom.cc-floating {
    max-width: 24em !important;
  }
}
@media screen and (min-width: 1200px) {
  .cc-theme-custom.cc-banner > .container {
    max-width: 1060px;
  }
  .cc-theme-custom.cc-floating {
    padding: 1em 1.8em !important;
  }
}

.cc-config-wrap {
  display: block;
}

@supports (position: sticky) or (position: -webkit-sticky) {
  .js-cc-spacer {
    display: none;
  }
}
.nav-link {
  padding-bottom: 0.75rem;
}

.navbar-toggler {
  width: 37px;
  height: 30px;
  margin-top: -5px;
  margin-right: 17.5px;
  background: transparent !important;
  cursor: pointer;
  border: none;
  border-radius: 0;
  padding: 0;
  align-self: center;
  z-index: 1000;
  position: relative;
}
.navbar-toggler .icon-bar {
  display: block;
  background-color: #3c536c;
  height: 3px;
  width: 37px;
  transition: all 0.2s linear;
}
.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 8px;
}
.navbar-toggler .top-bar {
  transform: translateY(11px) rotate(45deg);
}
.navbar-toggler .middle-bar {
  opacity: 0;
}
.navbar-toggler .bottom-bar {
  transform: translateY(-11px) rotate(-45deg);
}
.navbar-toggler[aria-expanded=false] .top-bar {
  transform: rotate(0);
}
.navbar-toggler[aria-expanded=false] .middle-bar {
  opacity: 1;
}
.navbar-toggler[aria-expanded=false] .bottom-bar {
  transform: rotate(0);
}
.navbar-toggler:focus {
  outline: none;
}
@media (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }
}

.dropdown-menu {
  border: none;
  padding: 0;
}

.ba-tabs .nav-item {
  position: relative;
  flex: 1 1 auto;
  text-align: center;
  margin: 0;
}
.ba-tabs .nav-item .nav-link {
  border-width: 0;
  display: block;
  width: 100%;
  margin-top: 0;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  padding: 16px 0 15px 0;
  text-align: center;
}
.ba-tabs .nav-item .nav-link:hover, .ba-tabs .nav-item .nav-link:focus, .ba-tabs .nav-item .nav-link:active {
  border-width: 0;
}
.ba-tabs .nav-item.active .nav-link span {
  opacity: 1;
}

.ba-tabs-top {
  border-bottom-width: 0;
}
.ba-tabs-top .nav-item .nav-link {
  color: #fbfbfb;
}
.ba-tabs-top .nav-item .nav-link span {
  transition: opacity 0.2s linear;
  opacity: 0.7;
}
.ba-tabs-top .nav-item .nav-link.active span {
  opacity: 1;
}
.ba-tabs-top .nav-item .nav-link:hover, .ba-tabs-top .nav-item .nav-link:focus, .ba-tabs-top .nav-item .nav-link:active {
  border-width: 0;
}
.ba-tabs-top .nav-item .nav-link:hover span, .ba-tabs-top .nav-item .nav-link:focus span, .ba-tabs-top .nav-item .nav-link:active span {
  opacity: 1;
}
.ba-tabs-top .nav-item .nav-link::before {
  content: "";
  display: block;
  z-index: 10000;
  position: absolute;
  bottom: -10px;
  left: calc(50% - 36px);
  width: 0;
  height: 0;
  border-left: 36px solid transparent;
  border-right: 36px solid transparent;
  border-bottom: 20px solid white;
}
.ba-tabs-top .nav-item.active .nav-link span {
  opacity: 1;
}
.ba-tabs-top .nav-item .nav-link {
  background-color: #d8d8d8;
  color: #2e4159;
  transition: all 0.2s ease-in-out;
  padding: 25px 0;
  text-transform: uppercase;
}
.ba-tabs-top .nav-item .nav-link span {
  opacity: 1;
  transition: none;
}
.ba-tabs-top .nav-item .nav-link::before {
  opacity: 0;
}
.ba-tabs-top .nav-item .nav-link.active,
.ba-tabs-top .nav-item .nav-link:hover,
.ba-tabs-top .nav-item .nav-link:focus,
.ba-tabs-top .nav-item .nav-link:active, .ba-tabs-top .nav-item.active .nav-link {
  background-color: #626262;
  color: #fff;
}
.ba-tabs-top .nav-item .nav-link.active::before,
.ba-tabs-top .nav-item .nav-link:hover::before,
.ba-tabs-top .nav-item .nav-link:focus::before,
.ba-tabs-top .nav-item .nav-link:active::before, .ba-tabs-top .nav-item.active .nav-link::before {
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #626262;
  border-bottom-width: 0;
}

:root {
  --loader-size: 48px;
  --loader-dot-size: 5px;
  --loader-height: 100px;
  --loader-margin-top: 50px;
  --loader-margin-bottom: 50px;
}

.has-load-delay {
  opacity: 1;
  transition: opacity 0.2s linear;
}
.has-load-delay .hide-while-loading {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.content-is-loading {
  opacity: 0.3;
}
.content-is-loading .hide-while-loading {
  opacity: 0;
}

.ba-form-submitted .btn {
  cursor: default;
  background-color: #e9ecef;
}

.loader-outer {
  margin: var(--loader-margin-top) auto var(--loader-margin-bottom);
  max-width: 200px;
  height: var(--loader-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-dot-slide {
  height: var(--loader-size);
  width: var(--loader-size);
  color: #2e4159;
  display: inline-block;
  position: relative;
  border: 2px solid;
  animation: rotate-dot-panel 4s 0.5s cubic-bezier(0.25, 0.46, 0.61, 0.4) infinite;
}
.loader-dot-slide::before, .loader-dot-slide::after {
  content: "";
  position: absolute;
  color: inherit;
}
.loader-dot-slide::before {
  height: 40%;
  width: 40%;
  border: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.loader-dot-slide::after {
  height: 0;
  width: 0;
  border: var(--loader-dot-size) solid;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  margin: 1px;
  animation: move-dot 4s ease infinite;
}

@keyframes rotate-dot-panel {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes move-dot {
  0%, 100% {
    left: 0;
    top: 0;
  }
  25% {
    left: 0;
    top: 75%;
  }
  50% {
    left: 75%;
    top: 75%;
  }
  75% {
    left: 75%;
    top: 0%;
  }
}
.ba-image-fill {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-container-default {
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}
.text-container-default .module-header .lead {
  max-width: 100%;
}
.text-container-default .module-header .text {
  max-width: 100%;
}

.centered-form-container {
  order: 2;
  max-width: 460px;
}